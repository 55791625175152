// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appliances-options-js": () => import("./../../../src/pages/appliances-options.js" /* webpackChunkName: "component---src-pages-appliances-options-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-cupboards-js": () => import("./../../../src/pages/cupboards.js" /* webpackChunkName: "component---src-pages-cupboards-js" */),
  "component---src-pages-decor-js": () => import("./../../../src/pages/decor.js" /* webpackChunkName: "component---src-pages-decor-js" */),
  "component---src-pages-dressing-rooms-js": () => import("./../../../src/pages/dressing-rooms.js" /* webpackChunkName: "component---src-pages-dressing-rooms-js" */),
  "component---src-pages-hallways-js": () => import("./../../../src/pages/hallways.js" /* webpackChunkName: "component---src-pages-hallways-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchens-js": () => import("./../../../src/pages/kitchens.js" /* webpackChunkName: "component---src-pages-kitchens-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-stands-js": () => import("./../../../src/pages/stands.js" /* webpackChunkName: "component---src-pages-stands-js" */)
}

